/* -------------- layout RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .page-main-row{
        padding: 70px 0;
    }

    .page-head{
        margin-bottom: 40px;
    }
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .breadcrumb-row{
        padding: 10px 0;
        .breadcrumb{
            a, p{
                font-size: .8125rem /* 13/16 */;
            }
        }
    }

    .page-main-row{
        padding: 50px 0;
    }

    .page-head{
        margin-bottom: 30px;
        p{
            font-size: 1.75rem /* 28/16 */;
            letter-spacing: 1px;
        }
    }
}

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }