/* -------------- container --------------*/
.frame{
	max-width: $w_pc_b;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding-left: 40px;
	padding-right: 40px;
}

.video-frame {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	iframe, object, embed{
		@include size(100%);
		@include abs_pos($t:0, $l:0);
	}
}

.aside-frame{
	--c-border: #eeeeee;
	align-items: flex-start;
	display: grid;
	grid-template-columns: 330px auto;
	gap: 60px;
	aside{
		@extend %clearStyle;
	}
	.aside-head{
		background: url(../images/repeat-green.jpg);
		padding: 50px 30px;
		p{
			color: var(--c-white);
			font-size: 2.25rem /* 36/16 */;
			font-weight: bold;
			line-height: 1;
			letter-spacing: 2px;
			text-transform: uppercase;
			position: relative;
			&:before{
				@include abs_pos($l:0, $b: -13px);
				@include size(45px, 5px);
				background: rgba(white, .3);
				content: '';
			}
			span{
				color: #1bfcff;
			}
		}
	}
	.aside-menu{
		margin-top: 2px;
		li{
			border-top: 1px solid var(--c-border);
			border-bottom: 1px solid var(--c-border);
			padding: 1px 0;
			&.active a{
				background: var(--c-border);
			}
		}
		a{
			display: block;
			color: var(--c-font);
			font-size: 1.125rem /* 18/16 */;
			line-height: 1.4;
			padding: 17px 30px;
			padding-left: 50px;
			position: relative;
			&:before{
				@include abs_pos($t:18px, $l:30px);
				content: '\f0da';
				color: var(--c-main);
				font-family: 'Font Awesome 6 Free';
				font-weight: bold;
				font-size: 1rem /* 16/16 */;
			}
			&:hover{
				color: var(--c-main);
			}
		}
	}
}