/* -------------- container RWD --------------*/
@media (max-width: $w_pc_b){ 
	.aside-frame{
		grid-template-columns: 240px auto;
		gap: 30px;
		.aside-head{
			padding: 25px 15px 35px;
			p{
				font-size: 1.875rem /* 30/16 */;
			}
		}
		.aside-menu{
			a{
				font-size: 1rem /* 16/16 */;
				padding: 12px 20px;
				padding-left: 30px;
				&:before{
					top: 12px;
					left: 15px;
				}
			}
		}
	}
}

// @media (max-width: $w_pc){ }

@media (max-width: 1200px){ 
	.aside-frame{
		grid-template-columns: 1fr;
		gap: 40px;
		.aside-menu{
			@include flex;
			gap: 10px;
			margin-top: 10px;
			li{
				border: 1px solid var(--c-border);;
				padding: 1px;
			}
		}
	}
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .frame{
		padding-left: 20px;
		padding-right: 20px;
	}
	.aside-frame{
		.aside-head{
			padding: 10px 15px;
			p{
				font-size: 1.5rem /* 24/16 */;
				&:before{
					display: none;
				}
			}
		}
		.aside-menu{
			gap: 5px;
			a{
				font-size: .9375rem /* 15/16 */;
				padding: 5px;
				padding-left: 15px;
				&:before{
					font-size: .75rem /* 12/16 */;
					left: 5px;
					top: 8px;
				}
			}
		}
	}
}

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }