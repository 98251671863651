/* -------------- header --------------*/
header{
    background: var(--c-white);
    position: sticky;
    top: 0;
    z-index: 2;
}

.header{
    @extend %clearStyle;
    padding: 30px 0;
    position: relative;
    z-index: 2;
    .frame{
        @include flex($ai:center, $jfc:space-between);
    }
    .logo{
        @include maw(365px);
        display: block;
        img{
            display: block;
            width: 100%;
        }
    }
    .main-menu{
        display: flex;
        >li{
            &.active > a{
                background: var(--c-main);
                color: var(--c-white);
                &:after{
                    color: var(--c-white);
                }
            }
            &.has-sub {
                position: relative;
                > a{
                    position: relative;
                    &:after{
                        @include abs_pos($t:18px, $r:10px);
                        color: var(--c-fontLight);
                        content: '\f107';
                        font-family: 'Font Awesome 6 Free';
                        font-weight: bold;
                        font-size: .875rem /* 14/16 */;
                    }
                }
            }
            >a{
                border-radius: 30px;
                color: var(--c-font);
                display: block;
                font-size: 1.125rem /* 18/16 */;
                line-height: 1;
                padding: 15px 30px;
                &:hover{
                    background: var(--c-main);
                    color: var(--c-white);
                    &:after{
                        color: var(--c-white);
                    }
                }
            }
        }
    }
    .sub-menu{
        @include abs_pos($b:0, $l:50%);
        display: none;
        transform: translate(-50%, 100%);
        text-align: center;
        width: 200px;
        li{
            +li{
                border-top: 1px solid var(--c-white);
            }
        }
        a{
            background: var(--c-fontLight);
            color: var(--c-white);
            display: block;
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
            padding: 10px;
            &:hover{
                background: var(--c-main);
            }
        }
    }
    .active-row{
        @include flex($ai:center);
        gap: 20px;
    }
    .keyword-form{
        align-items: center;
        border: 1px solid var(--c-border);
        border-radius: 3px;
        display: flex;
        padding: 10px;
        width: 255px;
        gap: 10px;
        input{
            font-size: 1rem /* 16/16 */;
            flex: 1;
            line-height: 1;
            padding: 0;
            width: 100%;
        }
        button{
            background: none;
            color: var(--c-fontLight);
            font-size: 1.125rem /* 18/16 */;
            line-height: 1;
            padding: 0;
            &:hover{
                color: var(--c-active);
            }
        }
    }
}

.mobile-toggle {
    @include size(28px, 30px);
    background: none;
    cursor: pointer;
    display: none;
    transform: translateY(13px);
    padding: 0;
    z-index: 4;

    &.open {

        span,
        span:before,
        span:after {
            background: var(--c-main);
        }

        span {
            background: none;

            &:before {
                transform: rotate(45deg) translate(6px, 5px);
            }

            &:after {
                transform: rotate(-45deg) translate(7px, -8px);
            }
        }
    }

    span {
        display: block;
        position: relative;

        &:before,
        &:after {
            @include abs_pos($t: -9px, $l: 0);
            content: "";
        }

        &:after {
            top: 9px;
        }
    }

    span,
    span:before,
    span:after {
        @include size(100%, 4px);
        backface-visibility: hidden;
        border-radius: 2px;
        background: var(--c-font);
        transition: all .4s ease;
    }
}