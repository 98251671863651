/* -------------- application --------------*/
.page-application{
    @extend %clearStyle;
    .application-row{
        position: relative;
    }
    .content-row{
        align-items: flex-start;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .left-content{
        border-left: 1px solid #dddddd;
        padding: 0 80px;
        margin-left: 10px;
        margin-bottom: 270px;
        position: relative;
        &:before{
            @include abs_pos($t:0, $l:-10px);
            @include size(40px);
            background: url(../images/ic-application.png) no-repeat;
            background-size: contain;
            content: '';
        }
        p{
            color: var(--c-font);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.6;
            + p{
                margin-top: 20px;
            }
        }
        .title{
            color: var(--c-font);
            font-size: 1.625rem /* 26/16 */;
            font-weight: bold;
        }
        .slogon{
            color: var(--c-main);
            font-size: 1.25rem /* 20/16 */;
            font-weight: bold;
        }
    }
    .right-img{
        img{
            display: block;
            width: 100%;
        }
    }
    .dec-img-row{
        @include abs_pos($l:50%, $b:0);
        transform: translateX(-42%);
        img{
            display: block;
            max-width: 100%;
        }
    }
}