/* -------------- header RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .header {
        .logo{
            max-width: 300px;
        }
        .main-menu > li{
            &.has-sub > a:after{
                right: 5px;
                font-size: .75rem /* 12/16 */;
            }
            > a{
                font-size: 1rem /* 16/16 */;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        .keyword-form{
            width: 200px;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .header {
        padding: 20px 0;
        nav{
            background: #f9f9f9;
            border-top: 1px solid var(--c-border);
            position: fixed;
            top: 80px;
            left: 0;
            width: 100%;
            padding: 10px 15px;
            display: none;
        }
        .main-menu{
            justify-content: center;
        }
    }
    .mobile-toggle{
        display: block;
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .header {
        .main-menu{
            display: block;
            > li {
                + li{
                    border-top: 1px solid var(--c-border);
                }
                > a{
                    border-radius: 0;
                }
            }
        }
        .sub-menu{
            position: static;
            transform: translate(0, 0);
            width: 100%;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .header {
        padding: 10px 0;
        .frame{
            flex-direction: column;
            gap: 8px;
        }
        .active-row{
            gap: 10px;
        }
        nav{
            top: 97px;
            overflow-y: auto;
            max-height: calc(100vh - 98px);
        }
        .main-menu > li {
            &.has-sub > a:after{
                top: 13px;
            }
            > a{
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
        .keyword-form{
            padding: 5px;
        }
    }
}

// @media (max-width: $w_mo){ }