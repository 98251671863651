/* -------------- index RWD --------------*/
@media (max-width: $w_pc_b){ 
    .index-contact{
        .map{
            margin-top: 0;
            height: 400px;
        }
    }
}

@media (max-width: $w_pc){ 
    .index-head{
        .en-title{
            font-size: 2.125rem /* 34/16 */;
        }
        .ch-title{
            font-size: .875rem /* 14/16 */;
            padding-top: 5px;
        }
    }
    .index-about{
        padding: 70px 0;
        .des{
            margin-top: 20px;
        }
        .link{
            margin-top: 20px;
        }
    }
    .index-product {
        .left-section{
            width: 350px;
        }
        .slider-active{
            margin-top: 20px;
        }
        .product-slider{
            width: calc(100% - 350px);
        }
    }
    .index-product {
        .category {
            .content{
                .box{
                    padding: 2.5vw;
                    min-height: 422px;
                }
            }
            .category-title{
                font-size: 1.375rem /* 22/16 */;
                margin-top: 15px;
                margin-bottom: 10px;
            }
            .des{
                padding-left: 0;
                padding-right: 0;
                margin-top: 10px;
            }
        }
    }
    .index-service {
        padding: 80px 0;
        .sevice-item {
            .circle{
                max-width: 200px;
            }
            .content {
                .service-title a{
                    font-size: 1.375rem /* 22/16 */;
                }
                .des{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

@media (max-width: $w_pc_m){ 
    .index-product {
        .product-category{
            .left-img{
                display: none;
            }
        }
        .category-list{
            width: 100%;
        }
        .category{
            .content{
                .box{
                    padding: 30px 20px;
                }
            }
        }
    }
    .index-service {
        .service-head{
            gap: 10px;
            position: static;
            transform: translateX(0);
            margin-bottom: 20px;
            p{
                writing-mode: inherit;
            }
        }
        .sevice-list{
            gap: max(4vw, 20px);
        }
    }
}

@media (max-width: $w_tb_b){ 
    .index-product {
        .top-row{
            padding-top: 0;
        }
        .left-section{
            margin-bottom: 40px;
            width: 100%;
        }
        .product-slider{
            width: 100%;
        }
        .category {
            .content{
                .box{
                    min-height: 380px;
                }
            }
        }
    }
    .index-contact .contact-info-list .icon-row img{
        height: 35px;
    }
}

@media (max-width: $w_tb){ 
    .index-about{
        padding: 40px 0;
        .about-img{
            max-width: 300px;
            width: 100%;
        }
        .content{
            padding-left: 0;
            margin-top: 20px;
            width: 100%;
        }
    }
    .index-product {
        .category {
            &:nth-child(2n){
                background: var(--c-white);
            }
            .content{
                .box{
                    min-height: inherit;
                }
            }
            .img-box{
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .index-head{
        .en-title{
            font-size: 1.75rem /* 28/16 */;
        }
    }
    .index-product {
        .category{
            flex-direction: column-reverse;
            width: 100%;
            .content{
                height: inherit;
            }
            .category-title{
                font-size: 1.25rem /* 20/16 */;
            }
        }
    }
    .index-service {
        padding: 40px 0;
        .sevice-list{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        .sevice-item{
            .circle{
                max-width: 120px;
            }
            .content{
                margin-top: 20px;
                .service-title a{
                    font-size: 1.25rem /* 20/16 */;
                }
            }
        }
    }
    .index-contact {
        .frame{
            padding: 0;
        }
        .contact-info-list{
            grid-template-columns: repeat(2, 1fr);
            .contact-info{
                padding: 30px 10px;
                + .contact-info{
                    border-left: none;
                }
                &:nth-child(n+3){
                    border-top: 1px solid rgba(#fff, .5);
                }
                &:nth-child(2n){
                    border-left: 1px solid rgba(#fff, .5);
                }
            }
            .icon-row p{
                font-size: 1.125rem /* 18/16 */;
            }
            .info-data{
                margin-top: 10px;
            }
        }
    }
}

// @media (max-width: $w_mo){ }