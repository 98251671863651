/* -------------- layout --------------*/
* {
	box-sizing: border-box;
	outline: none;
	word-break: break-word;
}

html, body{
	@include maw(1920px);
	padding: 0;
	margin: 0 auto;
}

body{
	font-family: 'Barlow', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
}

.hide{
	display: none !important;
}

.clearfix{
	@extend %clearfix;
}

a, button{
	text-decoration: none;
	transition: all .4s ease;
}

.unreset{
	@extend %clearfix;
	line-height: initial;
	a{
		text-decoration: underline;
		transition: none;
	}
	img{
		max-width: 100% !important;
		height: auto !important; 
	}
	.oembed-provider-youtube {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe, object, embed{
			@include size(100%);
			@include abs_pos($t:0, $l:0);
		}
	}
	p{
		font-size: 1rem /* 16/16 */;
		line-height: 1.6;
	}
}

.img-limit{
	position: relative;
	&.banner-img{
		@include fiximg(100%, calc(750 / 1920 * 100%));
	}
	&.pro-img{
		@include fiximg(100%, calc(165 / 290 * 100%));
	}
	&.pro-category-img{
		@include fiximg(100%, calc(510 / 510 * 100%));
	}
}

.page-banner{
	img{
		display: block;
		width: 100%;
	}
}

.breadcrumb-row{
	@extend %clearStyle;
	background: var(--c-main);
	padding: 15px 0;
	.breadcrumb{
		@include flex($ai:baseline, $jfc:flex-end);
		li{
			@include lineLimit(1);
			max-width: 120px;
			+li{
				position: relative;
				padding-left: 15px;
				&:before{
					@include abs_pos($t:-2px, $l:5px);
					content: '/';
					color: var(--c-white);
					font-size: .875rem /* 14/16 */;
					opacity: .5;
				}
			}
		}
		a, p{
			color: var(--c-white);
			display: block;
			font-size: .875rem /* 14/16 */;
			line-height: 1.2;
			max-width: 100px;
		}
		a:hover{
			text-decoration: underline;
		}
	}
}

.page-main-row{
	padding: 100px 0;
}

.page-head{
	margin-bottom: 60px;
	position: relative;
	&:before{
		@include abs_pos($t:50%, $l:0);
		@include size(100%, 1px);
		background: #dddddd;
		content: '';
	}
	p{
		background: var(--c-white);
		display: inline-block;
		font-size: 2rem /* 32/16 */;
		font-weight: bold;
		line-height: 1;
		margin: 0;
		padding-right: 15px;
		position: relative;
		&:before{
			@include abs_pos($t:-15px, $r:-15px);
			@include size(5px, 15px);
			border: 5px solid var(--c-main);
			content: '';
		}
		span{
			color: var(--c-main);
		}
	}
}

.is-wrapper p, .is-wrapper td, .is-wrapper li, .is-wrapper label{
	font-weight: normal;
}