/* -------------- temp RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

@media (max-width: $w_pc_m){ 
    .page-about .bottom-row{
        gap: 0 40px;
    }
}

@media (max-width: $w_tb_b){ 
    .page-about .bottom-row{
        grid-template-columns: 1fr;
        gap: 50px 0;
    }
}

@media (max-width: $w_tb){ 
    .page-about{
        .about-row{
            grid-template-columns: 1fr;
            gap: 40px;
        }
        .left-dec-img{
            padding-right: 0;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
        .content{
            .about-text-img{
                margin-left: 0;
            }
        }
        .bottom-row .sec-head{
            font-size: 1.375rem /* 22/16 */;
            padding-left: 15px;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-about{
        .content{
            p{
                font-size: 1rem /* 16/16 */;
            }
            .en-text{
                font-size: 1.5rem /* 24/16 */;
            }
        }
    }
}

// @media (max-width: $w_mo){ }