/* -------------- index --------------*/
.index-frame{
    @extend %clearStyle;
}

.index-head{
    align-items: flex-start;
    display: flex;
    .en-title{
        color: var(--c-fontLight);
        font-size: 2.8125rem /* 45/16 */;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1;
        span{
            color: var(--c-main);
        }
    }
    .ch-title{
        color: var(--c-font);
        font-size: 1rem /* 16/16 */;
        font-weight: bold;
        line-height: 1;
        padding-top: 8px;
        position: relative;
        margin-left: 10px;
        &:before{
            @include abs_pos($l:0, $b:-10px);
            @include size(30px, 2px);
            background: var(--c-active);
            content: '';
        }
    }
}

.index-about{
    padding: 120px 0;
    .frame{
        @include flex($ai:flex-start);
    }
    .about-img{
        width: 50%;
        img{
            display: block;
            width: 100%;
        }
    }
    .content{
        padding-left: 50px;
        width: 50%;
    }
    .des{
        margin-top: 40px;
        p{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1.6;
            + p{
                margin-top: 10px;
            }
        }
    }
    .link{
        margin-top: 40px;
        a{
            border-radius: 30px;
            background: var(--c-main);
            color: var(--c-white);
            display: block;
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            padding: 15px;
            text-align: center;
            width: 165px;
            &:hover{
                background: var(--c-active);
            }
        }
    }
}

.index-product{
    .top-row{
        @include flex($ai:flex-start);
    }
    .left-section{
        width: 450px;
    }
    .slider-active{
        align-items: center;
        display: flex;
        gap: 15px;
        margin-top: 30px;
        .slider-aw{
            color: var(--c-fontLight);
            font-size: 1.25rem /* 20/16 */;
            &:hover{
                color: var(--c-main);
            }
        }
        .slick-dots{
            display: flex;
            gap: 10px;
            position: relative;
            bottom: 0;
            li{
                @include size(7px);
                position: relative;
                margin: 0;
                &.slick-active{
                    button{
                        background: var(--c-main);
                    }
                }
            }
            button{
                @include abs_pos($t:0, $l:0);
                @include size(7px);
                border-radius: 50%;
                border: 1px solid var(--c-main);
                background: none;
                font-size: 0;
                padding: 0;
            }
        }
    }
    .product-slider{
        width: calc(100% - 450px);
        .img-box{
            padding: 0 10px;
        }
    }
    .product-category{
        @include flex;
        background: url(../images/repeat-2.jpg);
        margin-top: 50px;
        .left-img{
            width: 46.8%;
            img{
                display: block;
                width: 100%;
            }
        }
        .frame{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .category-list{
        @include flex;
        width: 100%;
    }
    .category{
        display: flex;
        flex-direction: column;
        width: 33.33%;
        &:nth-child(2n){
            flex-direction: column-reverse;
            .content{
                background: var(--c-white);
            }
            .sep .sep-center{
                background: var(--c-white);
            }
        }
        .content{
            @include flex($ai:center, $jfc:center);
            .box{
                padding: 2.5vw 3.5vw;
                min-height: 493px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
        .icon{
            margin-left: auto;
            margin-right: auto;
            width: max(3.5vw, 45px);
            img{
                display: block;
                width: 100%;
            }
        }
        .category-title{
            color: var(--c-font);
            font-size: 1.5rem /* 24/16 */;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            margin-top: 30px;
            margin-bottom: 20px;
        }
        .sep{
            position: relative;
            text-align: center;
            width: 100%;
            &:before{
                @include abs_pos($t:50%, $l:0);
                @include size(100%, 1px);
                background: var(--c-border);
                content: '';
            }
            .sep-center{
                align-items: end;
                background: url(../images/repeat-2.jpg);
                display: inline-flex;
                gap: 0 10px;
                padding: 5px 15px;
                position: relative;
            }
            svg, p{
                color: var(--c-active);
                text-transform: uppercase;
            }
        }
        .des{
            margin-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            p{
                color: var(--c-fontLight);
                font-size: 1rem /* 16/16 */;
                line-height: 1.5;
            }
        }
    }
}

.index-service{
    padding: 120px 0;
    position: relative;
    .service-head{
        @include abs_pos($t:120px, $l:50%);
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 15px;
        transform: translateX(-50%);
        svg, p{
            color: var(--c-main);
            font-size: 1.625rem /* 26/16 */;
        }
        p{
            text-transform: uppercase;
            letter-spacing: 3px;
            writing-mode: tb-rl;
        }
    }
    .sevice-list{
        display: flex;
        gap: 7vw;
    }
    .sevice-item{
        .circle{
            @include maw(240px);
            display: block;
            margin-left: auto;
            margin-right: auto;
            &:hover{
                transform: rotateY(360deg);
            }
            img{
                display: block;
                width: 100%;
            }
        }
        .content{
            margin-top: 30px;
            .service-title{
                text-align: center;
                a{
                    color: var(--c-font);
                    display: block;
                    font-size: 1.5rem /* 24/16 */;
                    font-weight: bold;
                    line-height: 1.2;
                    &:hover{
                        color: var(--c-main);
                    }
                }
            }
            .des{
                margin-top: 10px;
                padding-left: 15px;
                padding-right: 15px;
            }
            p{
                color: var(--c-fontLight);
                font-size: .9375rem /* 15/16 */;
                line-height: 1.5;
            }
        }
    }
}

.index-contact{
    .frame{
        background: url(../images/repeat-green.jpg);
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .contact-info-list{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        position: relative;
        .contact-info{
            padding: 30px 20px;
            + .contact-info{
                border-left: 1px solid rgba(#fff, .5);
            }
            &:nth-child(5){
                border-left: none;
            }
            &:nth-child(n+5){
                border-top: 1px solid rgba(#fff, .5);
            }
        }
        .icon-row{
            img{
                display: block;
                margin-left: auto;
                margin-right: auto;
                height: 45px;
            }
            p{
                color: var(--c-white);
                font-size: 1.25rem /* 20/16 */;
                font-weight: lighter;
                text-transform: uppercase;
                text-align: center;
                margin-top: 15px;
            }
        }
        .info-data{
            margin-top: 20px;
            p{
                color: var(--c-white);
                font-size: 1rem /* 16/16 */;
                line-height: 1.4;
                letter-spacing: 1px;
                text-align: center;
            }
            a{
                color: var(--c-white);
                &:not(.not-link):hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .map{
        height: 600px;
        margin-top: -135px;
        iframe{
            width: 100%;
            height: 100%;
        }
    }
}

.page-contact{
    padding-bottom: 0;
}