/* -------------- footer --------------*/
.footer{
    @extend %clearStyle;
    background: var(--c-main);
    padding: 60px 0;
    .frame{
        @include flex($jfc:space-between);
    }
    .logo{
        @include maw(370px);
        display: block;
        img{
            display: block;
            width: 100%;
        }
    }
    .footer-menu{
        @include flex($jfc:flex-end);
        gap: 10px 50px;
        a{
            color: var(--c-white);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .rights{
        @include flex($ai:center);
        gap: 10px 15px;
        margin-top: 20px;
        p, b{
            color: var(--c-white);
            font-size: .875rem /* 14/16 */;
            line-height: 1.2;
        }
        .right{
            text-align: center;
        }
        .designed{
            opacity: .7;
        }
    }
    .sns{
        display: flex;
        gap: 10px;
        a{
            color: var(--c-white);
            font-size: 1.25rem /* 20/16 */;
            &:hover{
                opacity: .7;
            }
        }
    }
}