/* -------------- application RWD --------------*/
@media (max-width: $w_pc_b){ 
    .page-application{
        .dec-img-row{
            max-width: 30vw;
            transform: translateX(-40%);
        }
    }
}

@media (max-width: $w_pc){ 
    .page-application{
        .left-content{
            padding: 0 40px;
            margin-left: 0;
        }
    }
}

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .page-application{
        .content-row{
            grid-template-columns: 1fr;
            gap: 40px;
        }
        .left-content{
            margin-bottom: 0;
            padding-right: 0;
        }
        .dec-img-row{
            position: static;
            max-width: 100%;
            padding-left: 54px;
            transform: translateX(0%);
            img{
                margin: 30px auto 0;
            }
        }
    }
}

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-application{
        .left-content{
            p{
                font-size: 1rem /* 16/16 */;
            }
            .title{
                font-size: 1.375rem /* 22/16 */;
            }
            .slogon{
                font-size: 1.0625rem /* 17/16 */;
            }
        }
        .dec-img-row{
            padding-left: 0;
        }
    }
}

// @media (max-width: $w_mo){ }