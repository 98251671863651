/* -------------- about --------------*/
.page-about{
    @extend %clearStyle;
    .about-row{
        align-items: flex-start;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .left-dec-img{
        display: block;
        padding-right: 40px;
        width: 100%;
    }
    .content{
        p{
            color: var(--c-font);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.6;
            + p{
                margin-top: 20px;
            }
        }
        .en-text{
            color: var(--c-main);
            font-weight: lighter;
            font-size: 1.875rem /* 30/16 */;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: 10px;
        }
        .about-text-img{
            display: block;
            max-width: 100%;
            margin-left: -30%;
        }
    }
    .bottom-row{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 80px;
        margin-top: 60px;
        .sec-head{
            border-left: 8px solid var(--c-main);
            color: var(--c-font);
            font-size: 1.625rem /* 26/16 */;
            font-weight: bold;
            line-height: 1;
            padding-left: 20px;
            position: relative;
            margin-bottom: 30px;
        }
    }
    .hostory-table{
        border-collapse: collapse;
        width: 100%;
        thead{
            background: var(--c-main);
            td{
                border: 1px solid var(--c-border);
                color: var(--c-white);
                font-size: 1.0625rem /* 17/16 */;
                text-align: center;
            }
        }
        tbody{
            td{
                border: 1px solid var(--c-border);
            }
            tr{
                &:nth-child(2n){
                    background: #f6f6f6;
                }
            }
        }
        td{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
            padding: 10px;
        }
        .title{
            span{
                display: inline-block;
                margin-right: 5px;
            }
        }
        .price{
            text-align: right;
        }
    }
    .exp-list{
        .exp-item{
            color: var(--c-font);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.4;
            padding-left: 20px;
            position: relative;
            &:before{
                @include abs_pos($t:2px, $l:0);
                color: var(--c-active);
                content: '\f152';
                font-family: 'Font Awesome 6 Free';
                font-weight: bold;
                font-size: .875rem /* 14/16 */;
            }
            + .exp-item{
                margin-top: 10px;
            }

            span{
                color: var(--c-main);
                font-weight: bold;
                padding: 0 5px;
            }
        }
    }
}