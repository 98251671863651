/* -------------- footer RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .footer{
        padding: 40px 0;
        .logo{
            max-width: 300px;
        }
        .footer-menu{
            gap: 10px 40px;
        }
        .rights{
            margin-top: 15px;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .footer{
        .rights{
            align-items: flex-end;
            flex-direction: column;
        }
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .footer{
        padding: 25px 0;
        .frame{
            align-items: center;
            flex-direction: column;
            gap: 15px;
        }
        .footer-menu {
            justify-content: center;
            gap: 10px 20px;
            a{
                font-size: 1rem /* 16/16 */;
            }
        }
        .rights{
            align-items: center;
            gap: 5px;
            text-align: center;
        }
    }
}

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }